import React, { useContext } from "react";
import { GlobalDataContext } from "../context/context";
import TransparentHeader from "../components/global/TransparentHeader";
import BaseLayout from "../components/global/BaseLayout";
import CallToAction2 from '../components/callToAction/CallToAction_2';

function Faq() {
    const { rpdata } = useContext(GlobalDataContext);


    const faqContent = [
      {
        title: "Why you should choose our company?",
        descripion: 'We opened our company in 2020, however, this was only after we had served in this industry for more than 15 years. We have already earned a reputation for providing quality welding at budget-friendly rates within the Midland, TX area. Our precision to details is unsurpassed, as are the tools, materials, and various equipment at our disposal. We can fabricate the likes of patios, carports, garages, awnings, fences, and other such metal structures, and make sure to operate well within all state codes and industry regulations. Our employees are both English and Spanish speaking, which broadens our horizons with regards to our clientele. Our list of services is impressive, and if you would like to know what they include, you need to continue reading this article.'
      },
      {
        title: "As the saying goes, “talk is cheap”, can you back up anything I have read in this article with customer reviews?",
        descripion:' Of course, if you would rather hear from our customers on what they thought of our prices, services, and overall workmanship, go to our testimonials page now. We are going to end our frequently asked questions article here, and hope that is managed to answer all of your questions? If, however, you still have unanswered questions by the end of this paragraph, or would like to speak to a member of our team, please do not hesitate to call us today at (432) 239-5818, or within the hours listed in this post. ',
      },
      {
        title: "Would your metal fabrication & metal fence company take a check for their services?",
        descripion:'Of course, we take cash or checks, whatever form of payment you find more convenient?' ,
      },
      {
        title: "Do you only work with residential clients?",
        descripion:'No, we also work with commercial ones. It matters not to us who we are working with, as our job remains the same in principle' ,
      },
      {
        title: "My welding project is somewhat complex, do you offer free estimates before I decide who to hire?",
        descripion:'Of course, we do, and if you do want to compare our rates to others in the Midland, TX area, you are free to do so. You may find that when it comes to savings, our prices are hard to match.',
      },
      {
        title: "When is your quality welding company open for business?",
        descripion:'The opening hours of Fefins Carports Welding and Fabrications are as follows: ' + rpdata?.dbPrincipal?.workdays[0].day + ' -> ' + rpdata?.dbPrincipal?.workHours[0].hour,
      },
      {
        title: "How can I be sure that your welding is up to industry standards?",
        descripion:'Easy, go to our gallery now for examples of our workmanship.',
      },
      {
        title: "Does your welding and fabrication work come with any warranties?",
        descripion:'Yes, they do, details on this are available, so feel free to contact us today.',
      },
      {
        title: "What other services do you offer?",
        descripion:'Please read the following list of our services: Metal Fabrication, Commercial and Residential Welding, Welding, Metal Repair, Carports, Garages, Storage Units, Patios, Awnings & Fences'
      },
    ];

    return (
      <BaseLayout PageName="Faq">
        <div className="md:max-w-full w-full">
          <TransparentHeader
            headertitle="Faq"
            Subheader="Faq"
            bgimg={`${rpdata?.stock?.[6]}`}
            // para cambiar el svg del header busquemos en la carpeta css y buscamos el archivo de svgStyles
            // los estilo de svg para el header empiezan svgTranspareHeader seguido de un numero
            svgClass="svgTranspareHeader2"
          />
        </div>
        <section className="relative w-full h-auto p-5 mb-2  from-white via-[#f5f5f5] to-white" >
          <section>
          <h2 className="text-center mb-10">
            Questions We Are Frequently Asked in Midland, TX
          </h2>
          <section className="flex flex-wrap z-20" >
            {faqContent.map((items, index) => {
              return (
                <div
                  className="p-5 shadow-xl w-[600px] h-auto mx-auto rounded-lg text-justify my-14 md:my-10 bg-white"
                  key={index}
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  <div className="flex flex-col gap-4">
                    <h5 className="text-red-700 font-bold">{items.title}</h5>
                    <div className="w-full border-b-[1px] border"></div>
                    <p>{items.descripion}</p>
                  </div>
                </div>
              );
            })}
          </section>
          
          </section>
          
        </section>
        
        <div className="mb-20">
                <CallToAction2
                    title={rpdata?.dbSlogan?.[4]?.slogan}
                    subtitle={rpdata?.dbSlogan?.[0]?.slogan}
                    phone={rpdata?.dbPrincipal?.phones?.[0]?.phone}
                    bgImages={rpdata?.stock?.[8]}
                />
            </div>
      </BaseLayout>
    );
}

export default Faq;
